<app-cover [type]="'text'" [title]="'بلاگ'" [breadcrumbs]="breadcrumb"></app-cover>
<div id="blogs">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 col-xs-12 order-md-0 order-1">
        <div class="content teaser" *ngIf="!loading">
          <article id="post-{{ i+1 }}" *ngFor="let item of blogs;let i = index">
            <div class="blog-item">
              <div class="image-wrapper">
                <a routerLink="/blog/{{ item.category?.slug }}/{{ item.id }}/{{ item.slug }}">
                  <img src="{{ item.mainImageUrl }}" alt="{{ item.title }}" class="img-fluid" />
                </a>
              </div>
              <div class="content-wrapper">
                <div class="d-flex align-items-center">
                  <a routerLink="/blog/{{ item.category?.slug  }}" class="category">
                    {{ item.category?.title }}
                  </a>
                  <span class="mr-auto mb-3"><i class="far fa-clock ml-2"></i> {{ item.createdAt| jalaliDate }}</span>
                </div>
                <h2>
                  <a class="text-dark" routerLink="/blog/{{ item.category?.slug }}/{{ item.id }}/{{ item.slug }}">
                    {{ item.title }}
                  </a>
                </h2>

                <div class="text-12" [innerHTML]="item.content | slice: 0: 200"></div>
                <hr>
                <div class="view-more">
                  <a class="text-uppercase text-12 text-dark" routerLink="/blog/{{ item.category?.slug }}/{{ item.id }}/{{ item.slug }}">ادامه مطلب</a>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="container" *ngIf="loading">
          <div class="row" >
            <div class="col-12 text-center pt-5">
              <app-loading [type]="1"></app-loading>
            </div>
          </div>
        </div>
       <div class="container" *ngIf="!loading && blogs.length == 0">
         <div class="row" >
           <div class="col-12 text-center">
             <img class="img-fluid" src="/assets/img/no-data.png" alt="No Data" width="400">
             <p class="mt-3">پستی یافت نشد.</p>
           </div>
         </div>
       </div>
      </div>

      <!-- Sidebar -->
      <div class="col-12 col-md-4 col-xs-12 order-md-1 order-0">
        <div id="search" class="mb-2">
          <form action="" (ngSubmit)="searchPost()">
            <input type="text" [(ngModel)]="search" name="search" class="form-control form-control-lg" placeholder="جستجو کنید...">
            <i class="fa fa-search"></i>
          </form>
        </div>
        <button class="btn btn-warning btn-block w-100 d-block d-md-none d-xs-block mb-3" type="button" data-toggle="collapse" data-target="#collapseCategory" aria-expanded="false" aria-controls="collapseExample">
          <i class="fas fa-filter ml-2"></i>دسته بندی

        </button>
        <div class="collapse mb-3" id="collapseCategory">
          <div class="card card-body">
            <app-blog-categories></app-blog-categories>
          </div>
        </div>
        <app-blog-categories class="d-none d-md-block d-xs-none" [sticky]="true"></app-blog-categories>
        <hr>
      </div>

    </div>
  </div>
</div>
