<app-cover [type]="'image'"
           [category]="blog.category"
           [title]="blog.title" [categoryID]="'/blog/' + blog.category"
           [bg]="blog.mainImageUrl"
           [breadcrumbs]="breadcrumb"
           [created]="blog.createdAt"
           [publishDateNormal]="true"
></app-cover>

<div id="blog">
  <div class="container" >
    <div class="row" *ngIf="!loading">
      <div class="col-12 col-md-8 col-xs-12 order-0 order-md-0" >
        <p [innerHTML]="blog.content"></p>
      </div>
      <div class="col-12 col-md-4 col-xs-12 order-1 order-md-1">
        <div id="related-posts">
          <h4 class="text-uppercase font-weight-normal">مطالب مرتبط</h4>
          <ul>
            <li class="mb-2" *ngFor="let post of related">
              <div class="media">
                <img src="{{ post.mainImageUrl }}" class="attachment-thumbnail size-thumbnail" alt="" loading="lazy">
              </div>
              <div class="content">
                <a routerLink="/blog/{{ post.category?.slug }}/{{ post.id }}/{{ post.slug }}">
                  {{ post.title }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="loading">
      <div class="row">
        <div class="col-12 text-center pt-5">
          <app-loading [type]="1"></app-loading>
        </div>
      </div>
    </div>
  </div>
</div>
