
        <button [type]="type ? type : 'button'" class="{{ className }}" id="{{id}}" [disabled]="disabled || loading">
            <span class="inline-middle ml-2" *ngIf="loading">
                <div class="spinner-border spinner-border-sm inline-middle" role="status">
                  <span class="sr-only" dir="rtl">Loading...</span>
                </div>
            </span>
            <span class="inline-middle">
                <ng-content></ng-content>
            </span>
        </button>
    