<div id="home">

  <!--  Hero Section  -->
  <div id="hero" [style]="'background-image: url(' + heroBG + ') !important'">
    <div class="container">
     <div class="newest" *ngIf="!loading">
       <div class="row justify-content-end" *ngFor="let item of newest| slice: 0:1">
         <div class="col-12 col-md-6 col-xs-12 d-none d-md-block d-xs-none">
           <div class="img">
             <a routerLink="/podcasts/{{item.category?.slug }}/{{ item.id }}/{{ item.slug }}">
               <img src="{{ item.episodeImage }}" alt="{{ item.title }}" class="img-fluid" >
             </a>
           </div>
         </div>
         <div class="col-12 col-md-6 col-xs-12">
           <div class="hero-desc">
             <div class="wrap" data-id="88fa2b2" data-element_type="widget" data-widget_type="heading.default">
               <div class="wrap-container">
                 <h1 class="podcast-title">
                   <a  class="text-white" routerLink="/podcasts/{{item.category?.slug}}/{{ item.id }}/{{ item.slug }}">{{ item.title }}</a>
                 </h1>
                 <div class="podcast-cat d-flex align-items-center">
                   <h5><a class="text-warning text-decoration-none" routerLink="/podcasts/{{ item.category?.slug }}">سطح {{ item.category?.title }}</a></h5>
                   <span class="mr-2 text-white"> | <i class="far fa-clock ml-2 mr-2"></i>{{ item.createdAt | jalaliRelative }}</span>
                 </div>
                 <div class="podcast-body d-none d-lg-block d-md-block d-xs-none" [innerHTML]="(item.description | slice: 0: 250 ) + '...'"></div>
                 <div class="podcast-body d-block d-lg-none d-md-none d-xs-block" [innerHTML]="(item.description | slice: 0: 150 ) + '...'"></div>

                 <ngx-audio-player [playlist]="playlist"  [autoPlay]="false"
                                   muted="muted"
                                   [displayTitle]="false"
                                   [displayVolumeControls]="displayVolumeControls"
                                   [displayRepeatControls]="displayRepeatControls"
                                   [disablePositionSlider]="disablePositionSlider"
                                   [displayDuration]="displayDuration">

                 </ngx-audio-player>
               </div>

             </div>
           </div>
         </div>
       </div>
     </div>
      <div class="container" *ngIf="loading">
        <div class="row">
          <div class="col-12 text-center pt-5">
            <app-loading [type]="1"></app-loading>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Levels -->

  <div id="levels" class="d-block d-md-none d-xs-block">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 col-xs-12">
          <div class="level-item yellow">
            <a routerLink="/podcasts/level_a">
              <h3>سطح A</h3>
              <p>15 اپیزود/ 1 فصل</p>
              <span class="text-14 text-white text-left d-block">مشاهده</span>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xs-12">
          <div class="level-item red">
            <a routerLink="/podcasts/level_b">
              <h3>سطح B</h3>
              <p>15 اپیزود/ 1 فصل</p>
              <span class="text-14 text-white text-left d-block">مشاهده</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--  About Section  -->
  <div id="about">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="about-wrap">
            <span class="about-label text-uppercase">Cafedeutsch</span>
            <h2>
              بهترین پادکست ها برای تقویت زبان
            </h2>
            <h4>
              کافه دویچ از رویکرد بسیار متفاوتی نسبت به سایر دوره ها یا وب سایت ها استفاده می کند. ما معتقدیم سریع ترین راه برای بهبود زبان آلمانی گوش دادن به مکالمات و بحث هایی است که می توانید درک کنید.
            </h4>
            <p>
              بسیاری از مردم سعی می کنند آلمانی خود را با گوش دادن یا خواندن چیزهایی که بسیار دشوار هستند، بهبود ببخشند. آنها فقط 40-50% را می فهمند، یعنی نصف وقت خود را تلف می کنند! در Cafe Deutsch Podcast، ما آلمانی را با سرعت کمتری ارائه می کنیم و از عبارات روزمره استفاده می کنیم. ما با دقت توضیح می دهیم که این عبارات به چه معنا هستند و چگونه از آنها به روشی روشن و سرگرم کننده استفاده کنیم.
            </p>
            <a class="btn btn-dark" routerLink="/about">
              درباره ما
            </a>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <img src="/assets/img/hero/about-cd.png" alt="about us" class="img-fluid">
        </div>
      </div>
    </div>
  </div>

  <!--  Featured Section  -->
  <div id="featured">
    <div class="container-fluid">
      <div class="row">
       <div class="col-12">
         <div class="featured-header mb-4 text-center">
           <h2>اپیزودهای اخیر</h2>
           <a routerLink="/podcasts" class="text-dark text-uppercase">نمایش همه</a>
         </div>
       </div>
      </div>
      <div class="row1" *ngIf="!loading">

        <owl-carousel-o [options]="customOptions">

          <ng-container *ngFor="let item of podcasts">
            <ng-template carouselSlide>
              <div class="col-12 col-md-12 col-xs-12" >
                <div class="podcast-item mb-3">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="img">
                        <a class="d-block" routerLink="/podcasts/{{item.category?.slug}}/{{ item.id }}/{{ item.slug }}">
                          <img src="{{ item.episodeImage }}" alt="{{ item.title }}" class="img-fluid" >
                        </a>
                        <div class="podcast-cat text-md-right text-center d-block d-md-none d-xs-block">
                          <h5 class="d-flex align-items-center">
                            <a class="text-warning text-decoration-none" routerLink="/podcasts/{{ item.category?.slug  }}">
                              <span class="badge badge-warning">سطح {{ item.category?.title }}</span>
                            </a>
                            <a><span class="badge badge-info mr-2" *ngIf="checkPublishDate(item.publish_date)">درحال انتشار</span></a>

                          </h5>
                        </div>
                      </div>

                    </div>
                    <div class="col-12 col-md-6">
                      <div class="desc">
                        <div class="podcast-title text-md-right text-center">
                          <h4>
                            <a class="text-dark text-decoration-none" routerLink="/podcasts/{{item.category?.slug }}/{{ item.id }}/{{ item.slug }}">{{ item.title }}</a></h4>
                        </div>
                        <div class="podcast-cat text-md-right text-center d-none d-md-block d-xs-none">
                          <h5 class="d-flex align-items-center">
                            <a class="text-warning text-decoration-none" routerLink="/podcasts/{{ item.category?.slug  }}">
                              <span class="badge badge-warning">سطح {{ item.category?.title }}</span>
                            </a>
                            <a><span class="badge badge-info mr-2" *ngIf="checkPublishDate(item.publish_date)">درحال انتشار</span></a>
                          </h5>
                        </div>
                        <div class="count-down mt-3" *ngIf="checkPublishDate(item.publish_date)">
                          <app-countdown [countdownDate]="item.publish_date"></app-countdown>
                        </div>
                        <div class="podcast-body d-none d-md-block d-xs-none" *ngIf="false" [innerHTML]="item.description | slice:0 : 100"></div>
                        <div class="view-more d-none d-md-flex d-xs-none">
                          <a routerLink="/podcasts/{{item.category?.slug }}/{{ item.id }}/{{ item.slug }}" class="text-dark text-uppercase mr-auto">مشاهده</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-block d-md-none d-xs-block">
                      <div class="view-more">
                        <a routerLink="/podcasts/{{item.category?.slug }}/{{ item.id }}/{{ item.slug }}" class="btn btn-outline-dark d-block  text-dark text-uppercase">مشاهده</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>

        </owl-carousel-o>
      </div>
      <div class="container" *ngIf="loading">
        <div class="row">
          <div class="col-12 text-center pt-5">
            <app-loading [type]="1"></app-loading>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Recent Posts -->
  <div id="blog">
    <owl-carousel-o [options]="blogOptions">

      <ng-container  *ngFor="let item of blog| slice: 0: 5">
        <ng-template carouselSlide>
          <div class="blog-item" [ngStyle]="{'background-image': ( item.mainImageUrl) ? 'url(' +  item.mainImageUrl + ')' : ''}">
            <div class="container">
              <div class="row align-content-end">
                <div class="col-12 col-md-4 col-xs-12">
                  <div class="content-wrapper">
                    <div class="d-flex align-items-center">
                      <a routerLink="/blog/{{ item.category?.slug }}" class="category">
                        {{ item.category?.title }}
                      </a>
                      <span class="mr-auto mb-3"><i class="far fa-clock ml-2"></i> {{ item.createdAt| jalaliDate }}</span>
                    </div>
                    <h2 class="blog-title">
                      <a class="text-white" routerLink="/blog/{{ item.category?.slug }}/{{ item.id }}/{{ item.slug }}">
                        {{ item.title }}
                      </a>
                    </h2>

                    <div class="text-12" [innerHTML]="item.content | slice: 0: 200"></div>
                    <hr>
                    <div class="view-more">
                      <a class="text-uppercase text-12 text-dark" routerLink="/blog/{{ item.category?.slug  }}/{{ item.id }}/{{ item.slug }}">ادامه مطلب</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </div>

  <!--  Donate Section  -->
  <div id="support">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="img">
            <img src="/assets/img/hero/support.png" alt="support" class="img-fluid">
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="desc">
            <h2 class="support-title">ما را حمایت کنید</h2>
            <p>                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد.</p>
            <a routerLink="/donate" class="btn btn-warning">حامی ما باشید</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
