<app-cover [type]="'text'" [title]="'یافت نشد'"></app-cover>

<div id="not-found">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 text-center">
        <h1>404</h1>
        <h2 class="mt-4">Not Found</h2>
      </div>
    </div>
  </div>
</div>
