<div id="cover" class="{{type}}" [ngStyle]="{'background-image': (bg) ? 'url(' + bg + ')' : ''}">
  <div class="container">

    <!--    Text  -->
    <div *ngIf="!loading && type == 'text'" class="row justify-content-center">
      <div class="col-12">
        <h1>{{ title }}</h1>
      </div>
    </div>

    <!--    Image -->
    <div *ngIf="!loading && type == 'image'" class="row justify-content-first">
      <div class="col-12 col-md-8">
        <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
        <div class="d-flex align-items-center"  >
          <h5 *ngIf="category" class="podcast-category d-flex align-items-center">
            <a routerLink="{{ categoryID }}">
              <span class="badge badge-warning">{{ category }}</span>
            </a>

            <a><span class="badge badge-info mr-2" *ngIf="publishDate && publishDate > now">درحال انتشار</span></a>

            <ng-container *ngIf="page == 'podcast'">
              <a class="cursor-pointer" *ngIf="isUser && !flagged" (click)="bookmark()"><i class="far fa-bookmark mr-2 text-white"></i> </a>
              <a class="cursor-pointer" *ngIf="isUser && flagged" (click)="unBookmark()"><i class="fas fa-bookmark mr-2 text-white"></i> </a>
            </ng-container>

          </h5>



          <span class="mr-auto text-white d-block d-md-none d-xs-block" *ngIf="publishDateNormal == true"><i class="far fa-clock ml-2"></i> {{ created | jalaliRelative }}</span>

        </div>
        <h1 class="podcast-title">{{ title }}</h1>
        <div class="podcast-audio" *ngIf="audio && publishDate < now">
          <ngx-audio-player [playlist]="audio"  [autoPlay]="false"
                            muted="muted"
                            [displayTitle]="false"
                            [displayVolumeControls]="displayVolumeControls"
                            [displayRepeatControls]="displayRepeatControls"
                            [disablePositionSlider]="disablePositionSlider"
                            [displayDuration]="displayDuration">

          </ngx-audio-player>
        </div>
        <div class="podcast-pub-date" *ngIf="publishDate && publishDate > now">
          <app-countdown [countdownDate]="publishDate"></app-countdown>
        </div>
      </div>
      <div class="col-12 col-md-4" *ngIf="podcastsList && podcastsList.length > 0">
        <div class="podcasts-list">
          <div class="list-header d-flex align-items-center">
            <span class="epis-count text-white pr-3 mb-2">
              {{ podcastsList.length }} اپیزود
               <span class="badge badge-danger" *ngIf="season">فصل {{ season }}</span>
            </span>
            <span class="mr-auto text-white"><i class="far fa-clock ml-2"></i> {{ created | jalaliRelative }}</span>
          </div>
          <ul class="list-unstyled">
            <li class="epis-item" *ngFor="let item of podcastsList;let i = index">
              <a class="text-white" routerLink="/podcasts/{{ item.category?.slug  }}/{{item.id}}/{{item.slug}}" routerLinkActive="active">
                <i class="far fa-play-circle ml-2"></i>{{i+1}}. {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-4 d-flex" *ngIf="publishDateNormal == true">
        <span class="mr-auto text-white d-none d-md-block d-xs-none"><i class="far fa-clock ml-2"></i> {{ created | jalaliRelative }}</span>
      </div>
    </div>

    <!--    Image static pages -->
    <div *ngIf="!loading && type == 'image2'" class="row justify-content-center">
      <div class="col-12 col-md-8">
        <h1 class="podcast-title">{{ title }}</h1>
        <p class="podcast-subtitle text-16">{{ subtitle }}</p>
      </div>
    </div>

    <div class="breadcrumb-bottom" *ngIf="!loading && type != 'image'">
      <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
    </div>

    <div class="row justify-content-center" *ngIf="loading">
      <div class="col-12 text-center">
        <app-loading type="1"></app-loading>
      </div>
    </div>

  </div>
</div>
