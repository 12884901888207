<app-cover [type]="'text'" [title]="categoryLabel" [breadcrumbs]="breadcrumb"></app-cover>
<div id="podcast-category">
  <div class="container">
    <div class="row">
      <aside class="col-12 col-md-3 col-xs-12">
        <!-- desktop -->
        <div class="desktop d-none d-md-block d-xs-none">
          <div id="season" class="filter">

            <h4>فصل</h4>

            <ul class="p-0 list-unstyled">
              <li><a routerLink="/podcasts/{{ cat }}" [queryParams]="{season: ''}" routerLinkActive="active">همه فصل ها</a></li>
              <li><a routerLink="/podcasts/{{ cat }}" [queryParams]="{season: '01'}" [queryParamsHandling]="'merge'" routerLinkActive="active">فصل 01</a></li>
              <li><a routerLink="/podcasts/{{ cat }}" [queryParams]="{season: '02'}" [queryParamsHandling]="'merge'" routerLinkActive="active">فصل 02</a></li>
            </ul>
          </div>
        </div>
         <!-- mobile -->
        <div class="desktop d-block d-md-none d-xs-block">
          <div id="season-mobile" class="filter">

            <button class="btn btn-warning btn-block w-100 d-block d-md-none d-xs-block mb-3" type="button" data-toggle="collapse" data-target="#collapseCategory" aria-expanded="false" aria-controls="collapseExample">
              <i class="fas fa-filter ml-2"></i>دسته بندی
            </button>
            <div class="collapse mb-3" id="collapseCategory">
              <div class="card card-body">
                <h4>فصل</h4>

                <ul class="p-0 list-unstyled">
                  <li><a routerLink="/podcasts/{{ cat }}" routerLinkActive="active">همه فصل ها</a></li>
                  <li><a routerLink="/podcasts/{{ cat }}" [queryParams]="{season: '01'}" [queryParamsHandling]="'merge'" routerLinkActive="active">فصل 01</a></li>
                  <li><a routerLink="/podcasts/{{ cat }}" [queryParams]="{season: '02'}" [queryParamsHandling]="'merge'" routerLinkActive="active">فصل 02</a></li>
                </ul>
              </div>
            </div>



          </div>
        </div>


      </aside>

      <main class="col-12 col-md-9 col-xs-12">
        <div class="container">

          <div class="row" *ngIf="!loading && podcasts.length > 0">
            <div class="col-md-6 col-xs-12" *ngFor="let item of podcasts">
              <div class="podcast-item mb-3">
                <div class="row">
                  <div class="col-6 col-md-6">
                    <div class="img">
                      <a routerLink="/podcasts/{{item.category?.slug }}/{{ item.id }}/{{ item.slug }}">
                        <img src="{{ item.episodeImage }}" alt="{{ item.title }}" class="img-fluid" >
                      </a>
                    </div>
                  </div>
                  <div class="col-6 col-md-6">
                    <div class="desc">
                      <div class="podcast-title">
                        <h4><a class="text-dark text-decoration-none" routerLink="/podcasts/{{item.category?.slug }}/{{ item.nid }}/{{ item.slug }}">{{ item.title }}</a></h4>
                      </div>
                      <div class="podcast-cat">
                        <h5 class="d-flex align-items-center">
                          <a class="text-warning text-decoration-none" routerLink="/podcasts/{{ item.category?.slug }}">
                            <span class="badge badge-warning">
                              سطح {{ item.category?.titile }}
                            </span>
                          </a>
                          <span class="badge badge-info mr-2" *ngIf="checkPublishDate(item.publish_date)">درحال انتشار</span>
                        </h5>
                      </div>
                      <div class="podcast-date mt-3">
                        <p>{{ item.createdAt | jalaliDate }}</p>
                      </div>
                      <div class="count-down mt-3" *ngIf="checkPublishDate(item.publish_date)">
                        <app-countdown [countdownDate]="item.publish_date"></app-countdown>
                      </div>
<!--                      <div class="podcast-body d-none d-md-block d-xs-none" [innerHTML]="item.body | slice:0 : 100"></div>-->
                      <div class="view-more d-none d-md-block d-xs-none">
                        <a routerLink="/podcasts/{{item.category?.slug}}/{{ item.id }}/{{ item.slug }}" class="text-dark text-uppercase">مشاهده</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 d-block d-md-none d-xs-block mt-3">
                    <div class="view-more">
                      <a routerLink="/podcasts/{{item.category?.slug}}/{{ item.id }}/{{ item.slug }}" class="btn btn-outline-dark d-block  text-dark text-uppercase">مشاهده</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container" *ngIf="loading">
            <div class="row">
              <div class="col-12 text-center pt-5">
                <app-loading [type]="1"></app-loading>
              </div>
            </div>
          </div>
          <div class="row"  *ngIf="!loading && podcasts.length == 0">
            <div class="col-12">
              <div class="alert alert-warning">
                آیتمی یافت نشد.
              </div>
            </div>
          </div>

        </div>
      </main>
    </div>

  </div>
</div>
