<app-cover [type]="'image2'" [title]="'درباره ما'" [bg]="background" [subtitle]="subTitle" [breadcrumbs]="breadcrumb"></app-cover>
<div id="about">
  <div class="section-1">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5">
          <div class="host-image">
            <img src="/assets/img/daw.jpeg" alt="Davoud Seyedi" class="img-fluid">
          </div>
        </div>
        <div class="col-12 col-md-7">
          <h4 class="text-uppercase">درباره Cafe Deutsch</h4>
          <p>
            پادکست Cafe Deutsch توسط تیم مجرب فارسی و  آلمانی زبان به عنوان زبان دوم با سابقه تدریس  در دبیرستان، بزرگسالان و دانشگاه اجرا می شود.  داود سیدی میزبان و راهنمای هر درس است و توضیحات مربوط به زبان و موضوع های  فرهنگی را ارائه می دهد.  [شخص دوم] فیلم‌نامه‌ها و ایده‌های داستانی درس‌ها را می‌نویسد و به ضبط مکالمات برای درس‌ها کمک می‌کند.

          </p>

          <h4>چرا این پادکست را ساختیم؟</h4>

          <p>          برای بسیاری از ایرانیان در سراسر جهان، یادگیری زبان آلمانی به دلیل مهاجرت تحصیلی یا کاری بسیار مهم است.  متأسفانه، منابع مفید و مؤثر برای یادگیری زبان آلمانی  بسیار اندک است و اکثرا در کلاس های آموزشی  شرکت می کنند که تا حدی به آنها کمک می کند.  پادکست Cafe Deutsch برای کمک به شما در ادامه بهبود زبان آلمانی طراحی شده است.
          </p>
          <h4>چه تفاوتی در پادکست Cafe Deutsch وجود دارد؟</h4>
          <p>          کافه دویچ  از رویکرد بسیار متفاوتی نسبت به سایر دوره ها یا وب سایت ها استفاده می کند.  ما معتقدیم سریع ترین راه برای بهبود زبان آلمانی گوش دادن به مکالمات و بحث هایی است که می توانید درک کنید.  بسیاری از مردم سعی می کنند آلمانی خود را با گوش دادن یا خواندن چیزهایی که بسیار دشوار هستند، بهبود ببخشند.  آنها فقط 40-50% را می فهمند، یعنی نصف وقت خود را تلف می کنند!  در Cafe Deutsch Podcast، ما آلمانی را با سرعت کمتری ارائه می کنیم و از عبارات روزمره استفاده می کنیم.  ما با دقت توضیح می دهیم که این عبارات به چه معنا هستند و چگونه از آنها به روشی روشن و سرگرم کننده استفاده کنیم.
          </p>
          <div class="d-flex align-items-center">
            <div class="button">
              <a class="btn btn-dark" routerLink="/contact">
                تماس با ما
              </a>
            </div>
            <div class="social">
              <ul class="m-0">
                <li class="list-inline-item mr-3"><a class="text-dark" href="https://facebook.com/cafedeutsch"><i class="fab fa-facebook"></i></a></li>
                <li class="list-inline-item mr-3"><a class="text-dark" href="https://twitter.com/cafedeutsch"><i class="fab fa-twitter "></i></a></li>
                <li class="list-inline-item mr-3"><a class="text-dark" href="https://instagram.com/cafedeutsch"><i class="fab fa-instagram "></i></a></li>
                <li class="list-inline-item mr-3"><a class="text-dark" href="https://telegram.org/cafedeutsch"><i class="fab fa-telegram "></i></a></li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
