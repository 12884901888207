<app-cover [type]="'image'"
           [page]="'podcast'"
           [category]="'سطح ' + podcast.category"
           [season]="podcast.season"
           [categoryID]="'/podcasts/'+ podcast.category"
           [title]="podcast.title" [bg]="podcast.episodeImage"
           [breadcrumbs]="breadcrumb"
           [podcastsList]="podcasts"
           [audio]="playlist"
           [created]="podcast.createdAt"
           [loading]="loading"
           [publishDate]="publishDate"
           [flagged]="isFlagged"
           (addBookmark)="addBookmark()"
           (actionRemoveBookmark)="removeBookmark()"
></app-cover>

<div id="podcast" *ngIf="!loading">
  <div class="container">
    <div class="row" *ngIf="!loading">
      <div class="col-12 col-md-8 col-xs-12">
        <div class="details">
          <div class="row justify-content-center">
            <div class="col-12 col-md-6">
              <ul class="list-unstyled d-flex">
                <li class="season ml-2">
                  <h4 class="mb-0">{{ podcast.season }}</h4>
                  <p>فصل</p>
                </li>
                <li class="level ml-2">
                  <h4 class="mb-0">{{ podcast.category }}</h4>
                  <p>سطح</p>
                </li>
                <li class="episode-count">
                  <h4 class="mb-0">{{ podcasts.length }}</h4>
                  <p>اپیزود</p>
                </li>

              </ul>
            </div>
          </div>
        </div>

        <div class="body">
          <h4 class="mb-3">متن اپیزود</h4>
          <p [innerHTML]="podcast.description"></p>
        </div>

<!--        <div class="tags" *ngIf="podcast.tag.length > 0">-->
<!--          <h4 class="mb-3">برچسب ها</h4>-->
<!--          <ul class="list-unstyled">-->
<!--            <li *ngFor="let item of podcast.tag"><a class="tag" href="">{{ item.title }}</a></li>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
      <div class="col-12 col-md-4 col-xs-12">
        <div class="sticky-info">
          <h3 class="text-center mb-3">کافه دویچی شوید</h3>
          <ul class="list-unstyled">
            <li class="mb-2"><i class="far fa-play-circle ml-2"></i> دسترسی نامحدود به هر اپیزود</li>
            <li class="mb-2"><i class="fa fa-dollar-sign ml-2"></i> کاملا رایگان</li>
            <li class="mb-2"><i class="far fa-file-archive ml-2"></i> تنوع موضوعی</li>
          </ul>
          <a class="btn btn-warning btn-block btn-sm mt-5 pt-3 pb-3 text-16" routerLink="/profile/panel/dashboard">شروع کنید</a>
        </div>
      </div>
    </div>

  </div>
</div>
