<!--Desktop-->
<nav class="navbar navbar-expand-lg justify-content-between navbar-light bg-light d-none d-md-flex d-xs-none" [class.profile] = "isProfile">
  <div class="container">
    <div class="navbar-brand site-branding ml-md-auto m-auto">
      <a class="logo d-flex align-items-center text-dark text-decoration-none" routerLink="/">
        <img src="/assets/img/logo.png" alt="Cafe Deutsch" width="50" class="logo-img">
        <h4 class="site-title mr-2 text-white d-none d-md-block d-xs-none">Cafe Deutsch</h4>
      </a>

    </div>

    <div class="collapse navbar-collapse" id="navbar-collapse-desk">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">صفحه اصلی</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/podcasts" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">اپیزود ها</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/blog" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">بلاگ</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">تماس با ما</a>
        </li>
        <li class="nav-item dropdown d-flex align-items-center">
          <a class="btn menu-dropdown nav-link btn-sm mr-auto pt-2 pb-2 pr-3 pl-3 d-flex align-items-center cursor-pointer" (click)="openModal()" *ngIf="!isUser" routerLink="/profile/panel">
            <i class="far fa-user-circle fa-2x ml-2"></i>
            حساب کاربری
          </a>
          <a class="text-14 menu-dropdown text-white nav-link dropdown-toggle cursor-pointer d-flex align-items-center" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="isUser">

            <i class="far fa-user-circle fa-2x ml-2"></i>

            سلام {{ userData.full_name ? userData.full_name : 'خوش آمدید' }}

            <span class="fa fa-caret-down inline-middle mr-1"></span>
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="isUser">
            <a class="dropdown-item menu-dropdown" routerLink="/profile/panel/dashboard">
              داشبورد
            </a>
            <a class="dropdown-item menu-dropdown" routerLink="/profile/panel/favorite">
              لیست علاقه مندی‌ها
            </a>
            <a class="dropdown-item menu-dropdown" routerLink="/profile/panel/setting">
              تنظیمات
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item menu-dropdown cursor-pointer" (click)="logout()">
                        <i class="fa fa-sign-out-alt text-20 inline-middle"></i>
              خروج از حساب کاربری
            </a>

          </div>
        </li>
      </ul>
    </div>

  </div>
</nav>


<!--Mobile-->
<nav class="navbar navbar-expand-lg  navbar-light bg-light d-block d-md-none d-xs-block">
  <div class="container-fluid d-block">

    <div class="row align-items-center">
      <div class="col-4">
        <button class="navbar-toggler d-flex" [class.active]="showDropDown" type="button" (click)="openDropDown('right')">
          <svg class="ss-svg-icon hamburger" *ngIf="!showDropDown"><use xlink:href="#hamburger">
            <svg viewBox="0 0 24 24" id="hamburger" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 5.5a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 0-1h-19a.5.5 0 0 0-.5.5zm.5 6.5a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1h-15zm0 6a.5.5 0 0 1 0-1h19a.5.5 0 0 1 0 1h-19z" fill="currentColor"></path></svg>
          </use></svg>
          <svg class="ss-svg-icon close" *ngIf="showDropDown"><use xlink:href="#close">
            <svg viewBox="0 0 24 24" id="close" xmlns="http://www.w3.org/2000/svg"><path d="M12.707 12l7.147 7.146a.5.5 0 0 1-.708.708L12 12.707l-7.146 7.147a.5.5 0 0 1-.708-.708L11.293 12 4.146 4.854a.5.5 0 1 1 .708-.708L12 11.293l7.146-7.147a.5.5 0 0 1 .708.708L12.707 12z" fill="currentColor"></path></svg>
          </use></svg>
        </button>
      </div>
      <div class="col-4 text-center">
        <a class="logo text-dark text-decoration-none" routerLink="/">
          <img src="/assets/img/logo.png" alt="Cafe Deutsch" width="40" class="logo-img">
          <h4 class="site-title mr-2 text-white d-none d-md-block d-xs-none">Cafe Deutsch</h4>
        </a>

      </div>
      <div class="col-4 d-flex">
        <a class="text-14 menu-dropdown text-white cursor-pointer mr-auto" (click)="openModal()"  routerLink="/profile/panel" *ngIf="!isUser">
          <i class="far fa-user-circle fa-2x ml-2"></i>
        </a>
        <a class="text-14 menu-dropdown text-white cursor-pointer mr-auto" [class.active]="showDropDownAccount" (click)="openDropDown('left')" *ngIf="isUser">

          <i class="far fa-user-circle fa-2x ml-2"></i>
        </a>
      </div>
    </div>


    <div class="mobile-dropdown collapse1 navbar-collapse1" id="navbar-collapse1" *ngIf="showDropDown" [@slideLeftAnimation]>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="changeMenuState()">صفحه اصلی</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/podcasts" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="changeMenuState()">اپیزود ها</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/blog" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="changeMenuState()">بلاگ</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="changeMenuState()">تماس با ما</a>
        </li>
      </ul>
    </div>
    <div class="mobile-dropdown collapse2 navbar-collapse2" id="navbar-collapse2" *ngIf="showDropDownAccount && isUser" [@slideRightAnimation]>
      <ul class="navbar-nav mr-auto">
        <div class="profile-info d-flex align-items-center">
          <div class="avatar">
            <img src="/assets/img/avatar.svg" alt="avatar" class="img-fluid">
          </div>
          <div class="name">
            <h5 class="m-0" *ngIf="userData.full_name">{{ userData.full_name ? userData.full_name : 'بدون نام' }}</h5>
            <p class="m-0 text-12" *ngIf="userData.mobile_number">{{ userData.mobile_number }}</p>
          </div>

        </div>
        <hr>
        <li class="nav-item">
          <a class="nav-link" routerLink="/profile/panel/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="changeMenuState()">داشبورد</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/profile/panel/favorite" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="changeMenuState()">لیست علاقه مندی‌ها</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/profile/panel/setting" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="changeMenuState()">تنظیمات حساب</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" (click)="logout()">
            <i class="fa fa-sign-out-alt ml-2"></i>
            خروج از حساب کاربری
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
