<notifier-container></notifier-container>

<div class="main-wrapper">
    <app-header *ngIf="hideHeader" [isProfile]="isProfile"></app-header>

    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>

    <app-footer *ngIf="hideHeader"></app-footer>
</div>
<app-login-modal [redirectLink]="link"></app-login-modal>
