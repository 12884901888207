<app-cover [type]="'image2'" [title]="'تماس با ما'" [bg]="background" [subtitle]="subTitle" [breadcrumbs]="breadcrumb"></app-cover>

<div id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-4 offset-md-1 col-xs-12">
        <div class="contact-left">
          <h5 class="text-uppercase font-weight-normal">ارتباط با ما</h5>
          <h3>تماس با ما</h3>
          <ul class="list-unstyled">
            <li><a class="text-dark" href="mailto:info@cafedeutsch.net"><i class="fa fa-envelope ml-2"></i> info@cafedeutsch.net</a></li>
<!--            <li><a class="text-dark" href="https://instagram.com/cafedeutsch"><i class="fab fa-instagram ml-2"></i> <span class="ltr">@cafedeutsch</span></a></li>-->
<!--            <li><a class="text-dark" href="https://telegram.com/cafedeutsch"><i class="fab fa-telegram ml-2"></i> <span class="ltr">@cafedeutsch</span></a></li>-->
<!--            <li><a class="text-dark" href="https://facebook.com/cafedeutsch"><i class="fab fa-facebook ml-2"></i> <span class="ltr">@cafedeutsch</span></a></li>-->
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-7  col-xs-12">
        <div class="contact-right">
          <h5 class="text-uppercase font-weight-normal">فرم تماس</h5>
          <h3>یک خط برای ما بنویسید</h3>
          <p>انتقادات و پیشنهادات شما ما را در ایجاد پادکست بهتر کمک می کند</p>

          <form  class="mt-2">
            <div class="form-row">
              <div class="form-group col-12 col-md-6">
                <input type="text" [(ngModel)]="webformModel.name" name="name" class="form-control" placeholder="نام و نام خانوادگی"
                       (keyup)="webformModelError.name = ''" [class.is-invalid]="webformModelError.name" />
                <div class="invalid-feedback" *ngIf="webformModelError.name">
                  {{ webformModelError.name }}
                </div>
              </div>
              <div class="form-group col-12 col-md-6">
                <input type="text" [(ngModel)]="webformModel.email" name="email" class="form-control" placeholder="پست الکترونیکی"
                       (keyup)="webformModelError.email = ''" [class.is-invalid]="webformModelError.email" />
                <div class="invalid-feedback" *ngIf="webformModelError.email">
                  {{ webformModelError.email }}
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12">
                <textarea name="message" [(ngModel)]="webformModel.message" id="message" cols="30" rows="5"
                          (keyup)="webformModelError.message = ''" [class.is-invalid]="webformModelError.message"
                          class="form-control" placeholder="پیام شما"></textarea>
                <div class="invalid-feedback" *ngIf="webformModelError.message">
                  {{ webformModelError.message }}
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="action">
                <app-btn [className]="'btn btn-warning'" (click)="postContact()" [loading]="btnLoading" [disabled]="btnLoading">
                  ارسال پیام
                </app-btn>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
