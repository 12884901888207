<div id="subscribe" class="d-none d-md-block d-xs-none">
  <div class="container">
    <div class="wrap">
      <div class="row">
        <div class="col-12 col-md-6 col-xs-12">
          <h3>خبرنامه پادکست های Cafedeutsch</h3>
          <p>با ثبت ایمیل خود می توانید از اپیزودهای جدید ما مطلع شوید</p>
        </div>
        <div class="col-12 col-md-6 col-xs-12">
          <form>
            <div class="form-group">
              <input type="text" [(ngModel)]="subscribeModel.email" name="email" class="form-control" placeholder="ایمیل خود را وارد کنید"  (keyup)="subscribeModelError.email = ''" [class.is-invalid]="subscribeModelError.email" />
              <div class="invalid-feedback" *ngIf="subscribeModelError.email">
                {{ subscribeModelError.email }}
              </div>
              <app-btn [className]="'btn btn-dark'" (click)="subscribe()" [loading]="btnLoading" [disabled]="btnLoading">
                اشتراک
              </app-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="footer d-none d-md-block d-xs-none">
  <div class="container">
    <div class="footer-top pb-5">
      <div class="row">
        <div class="col-12 col-md-3">
          <!--         <img class="mb-2" src="/assets/img/sample.png" alt="" width="80" height="80">-->
          <h3>Cafe Deutsch</h3>
          <p>
            کافه دویچ از رویکرد بسیار متفاوتی نسبت به سایر دوره ها یا وب سایت ها استفاده می کند. ما معتقدیم سریع ترین راه برای بهبود زبان آلمانی گوش دادن به مکالمات و بحث هایی است که می توانید درک کنید.
          </p>

        </div>
        <div class="col-12 col-md-3">
          <h5 class="text-uppercase">دسترسی سریع</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted1" routerLink="/">صفحه اصلی</a></li>
            <li><a class="text-muted1" routerLink="/podcasts">اپیزود ها</a></li>
            <li><a class="text-muted1" routerLink="/blog">بلاگ</a></li>
            <li><a class="text-muted1" routerLink="/contact">تماس با ما</a></li>
          </ul>
        </div>
        <div class="col-12 col-md-3">
          <h5 class="text-uppercase">منابع</h5>
          <ul class="list-unstyled text-small">
            <li><a class="text-muted1" routerLink="/about">درباره ما</a></li>
            <li><a class="text-muted1" routerLink="/contact">تماس با ما</a></li>
            <li><a class="text-muted1" href="#">حامی ما باشید</a></li>
            <li><a class="text-muted1" href="#">قوانین</a></li>
          </ul>
        </div>
        <div class="col-12 col-md-3">
          <h5 class="text-uppercase">ارتباط با ما</h5>
          <ul class="list-unstyled text-small">
            <li><a class="" href="mailto:info@cafedeutsch.net">info@cafedeutsch.net</a></li>
            <!--            <li><a class="text-muted" href="#">@cafedeutsch</a></li>-->
          </ul>

          <ul id="social-networks" class="p-0">
            <li class="list-inline-item mr-3"><a class="text-dark1" href="https://facebook.com/cafedeutsch"><i class="fab fa-facebook fa-2x"></i></a></li>
            <li class="list-inline-item mr-3"><a class="text-dark1" href="https://twitter.com/cafedeutsch"><i class="fab fa-twitter fa-2x"></i></a></li>
            <li class="list-inline-item mr-3"><a class="text-dark1" href="https://instagram.com/cafedeutsch"><i class="fab fa-instagram fa-2x"></i></a></li>
            <li class="list-inline-item mr-3"><a class="text-dark1" href="https://telegram.org/cafedeutsch"><i class="fab fa-telegram fa-2x"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="row">
        <div class="col-7 col-md-6 col-xs-7">
          <div class="copyright">
            <small class="d-block mb-3 text-muted">© 2017-2021 - Cafedeutsch.net</small>
          </div>
        </div>
        <div class="col-5 col-md-6 col-xs-5 text-right">
          <span class="rights text-gray text-12 text-muted">All right Reserved.</span>
        </div>
      </div>
    </div>
  </div>
</footer>


<!-- Mobile Toolbar -->
<div id="toolbar-mobile"  class="d-block d-md-none d-xs-block" [class.isFixed] = "isUser">

  <div class="row1" *ngIf="!isUser">
    <div class="col-12 text-center">
      <div class="copyright">
        <small class="d-block mb-3 text-white">© 2017-2021 - Cafedeutsch.net</small>
      </div>
    </div>
    <div class="col-12  text-center">
      <span class="rights text-12 text-white">All right Reserved.</span>
    </div>
  </div>

  <nav class="bottom-nav" *ngIf="isUser">
    <div class="bottom-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/profile/panel/dashboard">
      <div class="bottom-nav-link">
        <i class="fas fa-tachometer-alt fa-2x"></i>
        <span>داشبورد</span>
      </div>
    </div>
    <div class="bottom-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/profile/panel/favorite">
      <div class="bottom-nav-link">
        <i class="far fa-heart fa-2x"></i>
        <span>علاقه‌مندی</span>
      </div>
    </div>
    <div class="bottom-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/podcasts">
      <div class="bottom-nav-link">
        <i class="fas fa-podcast fa-2x"></i>
        <span>اپیزودها</span>
      </div>
    </div>
    <div class="bottom-nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/profile/panel/setting">
      <div class="bottom-nav-link ">
        <i class="fas fa-cog fa-2x"></i>
        <span>تنظیمات</span>
      </div>
    </div>

    <!--    <div class="bottom-nav-item">-->
    <!--      <div class="bottom-nav-link">-->
    <!--        <i class="material-icons">settings</i>-->
    <!--        <span>Setting</span>-->
    <!--      </div>-->
    <!--    </div>-->
  </nav>
</div>

