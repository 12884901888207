<div id="categories" [class.sticky] = "sticky">
  <h4 class="text-uppercase font-weight-normal">دسته بندی ها</h4>
  <ul class="ul-list">

    <li *ngFor="let item of categories">
      <a class="text-dark" routerLink="/blog/{{ item.slug }}" routerLinkActive="active">
        {{ item.title }}
      </a>
    </li>

  </ul>
</div>
